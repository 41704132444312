import { tns } from '../../../../../node_modules/tiny-slider/src/tiny-slider.js';

function sliderLogos () {
  const slider = document.querySelector('[data-slider]'),
        options = {
          container: '[data-slider]',
          mode: 'carousel',
          items: 1,
          controls: true,
          controlsPosition: 'bottom',
          nav: false,
          responsive: {
            576: {
              items: 2
            },
            768: {
              items: 3
            },
            992: {
              items: 4
            },
            1200: {
              items: 4
            }
          },
          speed: 400
        };

  if (slider) {
    const sliderLogos = tns(options);
    return sliderLogos;
  }
}

sliderLogos();